import MembershipCard from "./MembershipCard"

export const Fledger = () => {
	const x_membership = {
		commitments : [
			"Access to learning resources.",
			"Two 30-minute weekly individual mentorship sessions.",
			"Weekly practice sessions.",
			"End of week all-member review."
		],
		expectations : [
			"Interest in learning new things.",
			"60 minutes weekly personal practice time.",
			"Attendance at weekly mentorship sessions.",
		]
	}

	const y_membership = {
		commitments : [
			"Access to learning resources.",
			"Access to Monthly tech talks by industry experts.",
			"Access to Monthly leadership talks.",
			"Two 60-minute weekly individual mentorship sessions.",
			"60 minutes of member-class mentorship session weekly.",
			"End of week all-member review.",
			"Mentorship training",
		],
		expectations : [
			"Interest in learning new things.",
			"90 minutes weekly personal practice time.",
			"Attendance at weekly mentorship sessions.",
		]
	}

	//const group_membership = {
	//	commitments :  [
	//		"Access to learning resources.",
	//		"Two 60-minute weekly group mentorship sessions.",
	//		"60 minutes of member-class mentorship session weekly.",
	//		"End of week all-member meeting."
	//	],
	//	expectations : x_membership.expectations
	//}

	return (
		<div className="fledge-container">
			<div className="intro-zero">
				<p> At Fledge Factory we are committed to learning through implementation. </p>
				<p> Leadership is our core tenet. We encourage members to take ownership and be leaders throughout their learning journey. </p>
			</div>
			<div className="intro-zero">
				<p> By participating in team projects and peer-mentorship opportunities, members actively develop leadership skills with practical experience. </p>
				<p> The learning journey starts with the <strong>Fundamentals of Computer Programming in the Python programming language.</strong></p> 
			</div>
			<div className="intro-zero">
				Join us. Lead by learning.
			</div>
			<p className="coming-soon">
				<strong>Start Date: October 15th, 2024.</strong>
			</p>
			<div className="membership">
				<div className="mmmmmm">
					<h2 className="membership-header">Membership plans</h2>
				</div>
				<div className="membership-container">
					<MembershipCard 
						membership={"Colin"} 
						membership_metadata={x_membership} 
						membership_price={"$299"} spots={6}/>
					
					<MembershipCard 
						membership={"Willem"} 
						membership_metadata={y_membership} 
						membership_price={"$499"} spots={3}/>
				</div>
			</div>
			<div className="intro-zero">
				Why Fledge Factory?
			</div>
			<div className="intro-zero">
				<p>Learn, Lead, Build. These are our core tenets.</p>
				<p>With a Fledge Factory membership, you will have the opportunity to learn and implement programming solutions</p> 
				<p>We are committed to providing the resources, mentorship, team experience, and community neccessary for your success.</p>
			</div>
		</div>
    )
}