
import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import { addDoc, collection } from 'firebase/firestore';
import { db } from '../basebase';
const GetInTouch = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    education: '',
    hours: 'yes',
    grade: '10',
    notes: '',
    membership: state,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    try {
      const docRef = await addDoc(collection(db, 'member-reach-out'), formData);
      console.log("Document written with ID: ", docRef.id);
      alert("Thanks. We will be in touch shortly")
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h3>Fledge Factory</h3>
      </header>
      <div className='contact-nav'>
        <h2>Get in touch</h2>
        <div className="contact-form-return" onClick={() => navigate('/')}>
          <p>Home</p>
			</div>
      </div>
      <form className='contact-form' onSubmit={handleSubmit}>
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required/>

        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required/>

        <label htmlFor="phone">Phone number:</label>
        <input type="number" id="phone"  name="phone" value={formData.phone} onChange={handleChange} required/>

        <label htmlFor="hours">Can you commit to 2 - 3 hours of learning weekly?</label>
        <select id="hours" name="hours" value={formData.hours} onChange={handleChange}>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
        
        <label htmlFor="education">Level of education:</label>
        <select id="education" name="education"  value={formData.education} onChange={handleChange}>
          <option value="" selected disabled hidden></option>
          <option value="bachelors">Undergraduate</option>
          <option value="high-school">High School</option>
        </select>

        {
          formData.education === "high-school" &&
          <>
            <label htmlFor="grade">Grade:</label>
            <select id="grade" name="grade" value={formData.grade} onChange={handleChange}>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>

            <label htmlFor="notes">(For parents) Additional Notes:</label>
            <input type="text" id="notes" name="notes" className='hs-note'
              placeholder='Please add additional information or questions here.' 
              value={formData.notes} 
              onChange={handleChange} />
          </>

        }
        <button type="submit">Submit</button>
      </form>
      <footer className="App-footer">
        <p>@Fledge Factory 2024</p>
      </footer>
    </div>
  );
}

export default GetInTouch;