import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "./App";
import GetInTouch from "./components/GetInTouch";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="/getInTouch" element={<GetInTouch />}></Route>
      </Routes>
  </BrowserRouter>
  )
}

export default AppRouter;