import { useState } from "react";

import { Fledger } from "./components/Fledgers";
import WhoShouldJoin from "./components/WhoShouldJoin";

import './App.css';

function App() {
  const [group, setGroup] = useState("fledgers");

  const handleClickedGroup = (value) => {
    setGroup(value);
  }

  const isHeaderActive = (header) => {
    return group === header
  }
  
  return (
    <div className="App">
      <header className="App-header">
        <h3>Fledge Factory</h3>
      </header>
      <div className='App-container'>
        <div className='App-navigation'>
          <div className={isHeaderActive("fledgers") 
            ? 'App-nav-text-active' : 'App-nav-text'} 
            onClick={() => handleClickedGroup("fledgers")}>
              <p>Learning and Development</p>
          </div>
          <div className={isHeaderActive("software") 
            ? 'App-nav-text-active' : 'App-nav-text'} 
            onClick={() => handleClickedGroup("software")}>
              <p>Who should join?</p>
          </div>
        </div>
        {
          isHeaderActive("fledgers") && <Fledger />
        }
        {
          isHeaderActive("software") && <WhoShouldJoin />
        }
      </div>
      <footer className="App-footer">
        <p>@Fledge Factory 2024</p>
      </footer>
    </div>
  );
}

export default App;
