
const WhoShouldJoin = () => {
  return (
    <div className="fledge-container">
      <div className="intro-zero">
        <h4>
          High-Schoolers
        </h4>
        <p>
          Programming fosters essential skills such as problem-solving, creativity, and critical thinking.
          Programming encourages collaboration, teamwork, and it is a lifelong skill. Proficiency programming by 
          high-school students can establish a strong foundation for future endeavors.
        </p>
        <p>
          On a personal note, As a Software Engineer with some years of experience, programming is a lucrative skill to learn early. 
        </p>
        <p>
          At Fledge Factory, members in Grade 10 and above will have the opportunity to collaborate, and contribute to
          team projects while building problem-solving skills in programming. 
        </p>
      </div>
      <div className="intro-zero">
        <h4>
          Business Students
        </h4>
        <p>
          Programming allows business students to better understand technology's role in driving growth and efficiency. 
          Programming skills can facilitate collaboration with technical teams and enable the automation of repetitive tasks.
        </p>
        <p>
          Programming skills can differentiate business graduates in the job market, making them more versatile and valuable to employers.
        </p>
        <p>
          At Fledge Factory, there is a particular interest in Business students because of the on-going rapid innovation in the Finance field.
          Business students will learn and use Python programming for Data visualization and Business Analytics. 
        </p>
      </div>
      <div className="intro-zero">
        <h4>
          About Fledge Factory: Meet Tim
        </h4>
        <p>
          Hello. I am glad you're here. My name is Tim and I am a mentor at Fledge Factory. I am a Software Engineer in Vancouver and I enjoy programming.
        </p>
        <p>
          My vision for Fledge Factory is to create an hub of innovation. Flegde Factory will build a community of innovators by emphasizing leadership through learning.
        </p>
        <p>
          Fledge Factory emphasizes the importance of independence in learning through a self-driven learning model that will encourage members to take ownership of the 
          learning process. Mentorship and tutoring will be available all through the learning process to provide guidance and resolve issues.
        </p>
        <h5>
          About Fledge Factory: Mentorship
        </h5>
        <p>
          Mentorship goes in two directions. We believe the best way to learn is to teach. In addition to the presence of experienced mentors, 
          Fledge Factory will provide opportunities for members to demonstrate their knowledge and leadership abilities through mentorship opportunities. 
          Experienced mentors will provide guidance to members as they take ownership of mentoring their peers. 
          Mentors like other members are required to learn new things and continue to advance their knowledge in their fields of specialization.
        </p>
        <h5>
          About Fledge Factory: Learning Delivery
        </h5>
        <p><strong>Online: </strong> Mentoring sessions will be available on the Google Meet Platform</p>
        <p><strong>In-Person: </strong> In this early stage, we do not have a physical office. We will use spaces in the Vancouver Public Library (To be determined).</p>
      </div>
      <br></br>
    </div>
  )
}

export default WhoShouldJoin;