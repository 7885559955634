import { useNavigate } from "react-router-dom";

const MembershipCard = (props) => {
	const navigate = useNavigate();
	return (
		<div className="x-membership">
			<div className="headerHolder">
				<h2>{props.membership}</h2>
				<h3>{props.membership_price} CAD </h3>
				<p>per person/month</p>
			</div>
			<div className="commitment-container">
				<h3 className="commitment-header">Features:</h3>
				<ul>
					{props.membership_metadata.commitments.map((commitment) => (
						<li key={commitment}>{commitment}</li>
					))}
				</ul>
			</div>

			<div className="commitment-container">
				<h3 className="commitment-header">Success Requirements:</h3>
				<ul>
					{props.membership_metadata.expectations.map((expectation) => (
						<li key={expectation}>{expectation}</li>
					))}
				</ul>
			</div>
			<div className="memcard-footer">
				<h3>Availability: {props.spots}</h3>
			</div>
			<div className="memcard-get-started" onClick={() => navigate('/getInTouch', {state: props.membership})}>
				<h4>Get in touch</h4>
			</div>
		</div>		
	)
}

export default MembershipCard